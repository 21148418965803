import { Box, Button, ButtonGroup, Stack, Typography, Divider, Grid, Paper, IconButton } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { History as HistoryIcon, LocalPrintshop as LocalPrintshopIcon } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { CreditApplication } from '../../../data/types/CreditApplicationSchema'
import CreditSectionInformation from './creditSectionInformation'
import { TRootState, useAppSelector } from '../../../data/store'
import { appSelectors } from '../../../data/store/AppStore'
import { TranslatedEnum } from '../../../data/types/TranslatedEnum'
import { FormatCurrency } from '../../../services/Formatter'
import { Constants, ECreditApplicationStatus, EDecision, EFinancingProgram, User } from '../../../data/types'
import { creditSelectors } from '../../../data/store/CreditApplication'
import FormattedMerchantPaymentPlan from './FormattedMerchantPaymentPlan'

type Props = {
  creditApplication: CreditApplication
  handleEditBankruptciesClicked: (data: 'applicant' | 'coApplicant') => void
  editDisabled: boolean
  user: User | null
  onDisplayHistory: () => void
}

const CreditSection = ({
  creditApplication,
  handleEditBankruptciesClicked,
  editDisabled,
  user,
  onDisplayHistory,
}: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const planEnum = useAppSelector((state: TRootState) =>
    appSelectors.getPlanEnumForProgram(state, creditApplication.financingProgramId),
  )

  const lang = useAppSelector(appSelectors.getCurrentLang) as keyof TranslatedEnum
  const merchantPaymentPlan = useAppSelector(creditSelectors.getMerchantPaymentPlan)
  const isOnlyPrequalificationDone = useAppSelector(creditSelectors.isOnlyPrequalificationDone)
  let decisionBorderColor = 'warning.main'

  if (
    creditApplication.status === ECreditApplicationStatus.Cancelled ||
    creditApplication.status === ECreditApplicationStatus.Expired
  )
    decisionBorderColor = 'warning.main'
  else if (creditApplication.finalCreditDecision.decision === EDecision.declined) decisionBorderColor = 'error.main'
  else if (creditApplication.finalCreditDecision.decision === EDecision.accept) decisionBorderColor = 'success.main'

  const applicationIsDraft = creditApplication.status === ECreditApplicationStatus.Draft

  const messageDecision = () => {
    if (applicationIsDraft) return t('enum.eCreditApplicationStatus.draft')
    if (
      creditApplication.status === ECreditApplicationStatus.Cancelled ||
      creditApplication.status === ECreditApplicationStatus.Expired
    )
      return 'N/A'
    return t(`credit.${creditApplication.finalCreditDecision.decision}`)
  }
  return (
    <Paper elevation={3} sx={{ borderRadius: 2, p: 2, mt: 3, mb: 3 }}>
      <Box sx={{ m: 1 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <ButtonGroup variant="outlined" size="large">
            <Button
              id="btnViewCreditDecision"
              disabled={
                applicationIsDraft ||
                isOnlyPrequalificationDone ||
                !Constants.financingProgramConfigs[creditApplication.financingProgramId].useCreditSection ||
                !creditApplication.prequalificationDecision?.decision
              }
              onClick={() =>
                navigate(`/Applications/${creditApplication.financingProgramId}/${creditApplication.id}/decision`)
              }
            >
              {t('credit.credit')}
            </Button>
            <Button disabled={applicationIsDraft || editDisabled || isOnlyPrequalificationDone} size="small">
              <LocalPrintshopIcon fontSize="large" />
            </Button>
          </ButtonGroup>
          <Typography>
            {t('common.LastModification')} : {new Date(creditApplication.updatedOn).toLocaleDateString()} |{' '}
            {new Date(creditApplication.updatedOn).toLocaleTimeString()}
          </Typography>
        </Stack>
      </Box>
      <Divider variant="middle" />
      <Box sx={{ m: 1 }}>
        {isOnlyPrequalificationDone ? (
          <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 4, mt: 10 }} alignItems="center" justifyContent="start">
            <Typography
              variant="body1"
              component="div"
              sx={{ border: '2px solid', borderRadius: 2, p: 0.5, borderColor: decisionBorderColor }}
            >
              Prequalification:{' '}
              {creditApplication.prequalificationDecision.decision === EDecision.pending
                ? t('common.reportNotFound')
                : t(`credit.${creditApplication.prequalificationDecision.decision}`)}
            </Typography>
            <Typography variant="body1" component="div">
              {t('credit.interestRate')} : {creditApplication.prequalificationDecision.minInterestRate}% -{' '}
              {creditApplication.prequalificationDecision.maxInterestRate}%
            </Typography>
            <Typography variant="body1" component="div">
              {t('editCreditApplication.maximumLoanAmount')} :{' '}
              {FormatCurrency(creditApplication.prequalificationDecision.maxLoanAmount)}
            </Typography>
          </Stack>
        ) : (
          <Stack
            direction="row"
            spacing={{ xs: 1, sm: 2, md: 4, mt: 10 }}
            alignItems="center"
            justifyContent={merchantPaymentPlan ? 'space-between' : 'start'}
          >
            <Typography
              variant="body1"
              component="div"
              sx={{ border: '2px solid', borderRadius: 2, p: 0.5, borderColor: decisionBorderColor, float: 'right' }}
            >
              {messageDecision()}
            </Typography>

            {merchantPaymentPlan ? (
              <Stack direction="row">
                <Typography style={{ marginRight: '1rem' }}>Promo:</Typography>
                <FormattedMerchantPaymentPlan merchantPaymentPlan={merchantPaymentPlan} />
              </Stack>
            ) : (
              <Typography variant="body1" component="div">
                {t('credit.interestRate')} : {creditApplication.finalCreditDecision.interestRate}
                {creditApplication.finalCreditDecision.interestRate !== undefined && '%'}
              </Typography>
            )}

            <Typography variant="body1" component="div">
              {t('credit.plan')} :{' '}
              {creditApplication.finalCreditDecision.planName != null && planEnum != null
                ? planEnum[creditApplication.finalCreditDecision.planName ?? ''][lang]
                : 'N/A'}
            </Typography>

            {creditApplication.financingProgramId === EFinancingProgram.Auto && (
              <Typography variant="body1" component="div">
                {t('credit.cash')} : {creditApplication.decisionOverrides?.cashdownPercent}
                {creditApplication.decisionOverrides?.cashdownPercent !== undefined && '%'}
              </Typography>
            )}

            <Typography variant="body1" component="div">
              {t('credit.maxPayment')} : {FormatCurrency(creditApplication.finalCreditDecision.maxPmtAmount)}
            </Typography>

            <IconButton onClick={onDisplayHistory}>
              <HistoryIcon fontSize="large" color="primary" />
            </IconButton>

            {creditApplication.financingProgramId === EFinancingProgram.Auto && (
              <span>
                <Typography variant="body1" component="div">
                  {t('credit.refinancing')} : {FormatCurrency(creditApplication.refinancing?.amountToRefinance)}
                </Typography>

                <Typography variant="body1" component="div">
                  {t('credit.commitment')} : {FormatCurrency(creditApplication.refinancing?.engagementAmount)}
                </Typography>
              </span>
            )}
          </Stack>
        )}
      </Box>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} md={12} lg={6}>
          <CreditSectionInformation
            applicant={creditApplication.applicant}
            creditApplicationId={creditApplication.id}
            financingProgramId={creditApplication.financingProgramId}
            currentApplicant="applicant"
            handleEditBankruptciesClicked={handleEditBankruptciesClicked}
            editDisabled={editDisabled}
            userCanEditCreditApplication={user?.rights.canEditCreditApp === true}
            applicationIsDraft={applicationIsDraft}
            finalDecision={creditApplication.finalCreditDecision}
            prequalificationDecision={creditApplication.prequalificationDecision}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          {creditApplication.coApplicant !== null && (
            <CreditSectionInformation
              applicant={creditApplication.coApplicant}
              creditApplicationId={creditApplication.id}
              financingProgramId={creditApplication.financingProgramId}
              currentApplicant="coApplicant"
              handleEditBankruptciesClicked={handleEditBankruptciesClicked}
              editDisabled={editDisabled}
              userCanEditCreditApplication={user?.rights.canEditCreditApp === true}
              applicationIsDraft={applicationIsDraft}
              finalDecision={creditApplication.finalCreditDecision}
              prequalificationDecision={creditApplication.prequalificationDecision}
            />
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default React.memo(CreditSection)
