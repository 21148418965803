import { Container, createTheme, CssBaseline, LinkProps, ThemeProvider } from '@mui/material'
import { blue } from '@mui/material/colors'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { reportErrorToConsole } from '@src/services/error-logger'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BrowserRouter as Router, Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../data/store'
import { appSelectors, appStoreEffects } from '../../data/store/AppStore'
import { userActions, userEffects, userSelectors } from '../../data/store/UserStore'
import LoginPage from '../Home/LoginPage'
import AlertBanner from './AlertBanner'
import AppHeader from './AppHeader'
import ConflictDialog from './components/ConflictDialog'
import TopLevelRoutes from './Routes'

const LinkBehavior = React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>(
  (props, ref) => {
    const { href, ...other } = props
    // Map href (MUI) -> to (react-router)
    return <RouterLink ref={ref} to={href} {...other} />
  },
)

const isRunningLocal = process.env.NODE_ENV === 'development'

const App = (): JSX.Element | null => {
  const dispatch = useAppDispatch()
  const useDarkMode = useAppSelector(userSelectors.isDarkModeEnabled)
  const theme = useMemo(
    () =>
      createTheme({
        components: {
          MuiLink: {
            defaultProps: {
              component: LinkBehavior,
            } as LinkProps,
          },
          MuiButtonBase: {
            defaultProps: {
              LinkComponent: LinkBehavior,
            },
          },
          MuiInputBase: {
            defaultProps: {
              // Needed to prevent adding a global style for every input field
              disableInjectingGlobalStyles: true,
            },
          },
          MuiChip: {
            styleOverrides: {
              sizeSmall: {
                fontSize: '0.6rem',
              },
            },
          },
        },
        palette: {
          primary: { main: useDarkMode ? blue[200] : blue[700] },
          background: {
            default: useDarkMode ? '#1e1e1e' : '#ffffff',
          },
          mode: useDarkMode ? 'dark' : 'light',
        },
      }),
    [useDarkMode],
  )

  const [isLoaded, setLoaded] = useState(false)
  const dispatchEffect = useSideEffect()
  const needAuth = useAppSelector(userSelectors.needToAuthenticate)
  const isIcebergGrDevs = useAppSelector(userSelectors.isIcebergGrDevs)
  const conflict = useAppSelector(appSelectors.conflict)
  const deprecated = useAppSelector(appSelectors.isDeprecated)
  const { pathname } = window.location
  const isAuthenticate = pathname === '/auth-page'
  const { t } = useTranslation()

  const hasAlertBanner = isIcebergGrDevs || (!isRunningLocal && deprecated)

  useEffect(() => {
    dispatch(userActions.loadPreferences())
    Promise.all([dispatchEffect(appStoreEffects.initialize()), dispatchEffect(userEffects.loadUser())])
      .then(() => setLoaded(true))
      .catch(reportErrorToConsole)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  if (!isLoaded) return null
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        {needAuth && !isAuthenticate && <LoginPage />}
        {!needAuth && (
          <>
            <AlertBanner message="Production" show={isIcebergGrDevs} />
            <AlertBanner message={t('common.deprecated')} variant="error" show={!isRunningLocal && deprecated} />
            <AppHeader
              hasAlertBanner={hasAlertBanner}
              onToggleTheme={() => dispatch(userActions.setDarkModePreference(!useDarkMode))}
            />
            <Container maxWidth="xl" disableGutters>
              <TopLevelRoutes />
            </Container>
          </>
        )}
        <ConflictDialog open={conflict !== null} conflict={conflict} />
      </Router>
    </ThemeProvider>
  )
}

export default App
