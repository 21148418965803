import React from 'react'
import { Box, Button, Divider, Grid, Typography } from '@mui/material'
import { Control, FieldError, FieldErrorsImpl, Merge, useFieldArray, UseFormRegister, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { nanoid } from 'nanoid'
import OtherIncomeComponent from './OtherIncomeComponent'
import { ApplicantJob, JobSchema, ApplicantOtherIncome, CreditApplication } from '../../../../../../../data/types'
import DisplayJobInfosComponent from './DisplayJobsInfosComponent'
import EditJobDialog from './EditJobDialog'

type Props = {
  register: UseFormRegister<CreditApplication>
  errors: Merge<FieldError, (Merge<FieldError, FieldErrorsImpl<ApplicantOtherIncome>> | undefined)[]> | undefined
  formControl: Control<CreditApplication>
  name: 'coApplicant' | 'applicant'
  editDisabled: boolean
}
const ApplicantIncomeSection = ({ register, errors, name, formControl, editDisabled }: Props) => {
  const [open, setOpen] = React.useState<boolean>(false)
  const [editingItemIndex, setEditingItemIndex] = React.useState<number | undefined>(undefined)
  const handleClose = (event?: object, reason?: string) => {
    if (reason !== 'backdropClick') {
      setEditingItemIndex(undefined)
      setOpen(false)
    }
  }

  const handleOpen = (itemIdex?: number) => {
    setEditingItemIndex(itemIdex)
    setOpen(true)
  }

  const { t } = useTranslation()

  const {
    fields: otherIncomesFields,
    append: otherIncomesAppend,
    remove: otherIncomesRemove,
  } = useFieldArray({
    name: `${name}.otherIncomes`,
    control: formControl,
  })

  const {
    append: currentJobsAppend,
    remove: currentJobsRemove,
    update: currentJobsUpdate,
  } = useFieldArray({ name: `${name}.currentJobs`, control: formControl })

  const currentJobsFields = useWatch({
    control: formControl,
    name: `${name}.currentJobs`,
  })

  const addJobHandler = (data: ApplicantJob) => {
    currentJobsAppend(data)
    setOpen(false)
  }

  const updateJobHandler = (data: ApplicantJob, itemIndex: number) => {
    currentJobsUpdate(itemIndex, data)
    handleClose()
  }

  // Others Icomes
  const addOtherIncomesHandler = () => {
    otherIncomesAppend({ id: nanoid() } as ApplicantOtherIncome)
  }

  return (
    <>
      <Divider>
        <Typography variant="h4" component="span" gutterBottom>
          {t('editCreditApplication.income.label')}
        </Typography>
      </Divider>

      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, m: 3 }}>
        <Divider textAlign="left" sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Typography variant="h4" component="span" gutterBottom>
            {t('editCreditApplication.income.job.label')}
          </Typography>
        </Divider>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button variant="outlined" onClick={() => handleOpen()} disabled={editDisabled}>
          {t('editCreditApplication.income.job.addJob')}
        </Button>
      </Box>

      <Grid container item xs={12} spacing={2}>
        {currentJobsFields.map((item, index) => (
          <DisplayJobInfosComponent
            key={item.id}
            onDelete={() => currentJobsRemove(index)}
            onEdit={() => {
              handleOpen(index)
            }}
            data={item}
            editDisabled={editDisabled}
          />
        ))}

        <EditJobDialog
          defaultValue={
            editingItemIndex !== undefined
              ? currentJobsFields[editingItemIndex]
              : ({ ...JobSchema.getDefault(), id: nanoid() } as ApplicantJob)
          }
          onConfirm={(data: ApplicantJob) =>
            editingItemIndex !== undefined ? updateJobHandler(data, editingItemIndex) : addJobHandler(data)
          }
          onCancel={handleClose}
          open={open}
          title={
            editingItemIndex !== undefined
              ? t('editCreditApplication.income.job.editJob')
              : t('editCreditApplication.income.job.addJob')
          }
        />
      </Grid>

      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, m: 3 }}>
        <Divider textAlign="left" sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Typography variant="h4" component="span" gutterBottom>
            {t('editCreditApplication.income.other.label')}
          </Typography>
        </Divider>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button variant="outlined" onClick={addOtherIncomesHandler} disabled={editDisabled}>
          {t('editCreditApplication.income.other.addOther')}
        </Button>
      </Box>

      <Grid container item xs={12} spacing={2}>
        {otherIncomesFields.map((item, index) => (
          <OtherIncomeComponent
            key={item.id}
            register={register}
            formControl={formControl}
            errors={errors?.[index]}
            name={`${name}.otherIncomes.${index}`}
            onDeleteClicked={() => otherIncomesRemove(index)}
            editDisabled={editDisabled}
          />
        ))}
      </Grid>
    </>
  )
}
export default ApplicantIncomeSection
